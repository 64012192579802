import React from "react";
import { StaticQuery, graphql } from 'gatsby'


const Services = ({ data }) => {
  
  const services = data.serviceData
  const service_list = services.frontmatter.employee_services.group
  const leftArray = service_list.slice(0, 3);
  const rightArray = service_list.slice(3);

  return (
      <div className="bg-background-primary">
        <div className="container mx-auto">
          <div className="relative">
            <div className="pt-38 pb-67 lg:pb-1-21 relative flex flex-wrap">
              <div className="w-full md:w-1/2 bg-cyan d-service--info">
                  <div className="pt-62 pb-67 pl-5-3 pr-43 1md:pr-70 md:pb-50 xl:pr-60 lg:pl-12 xl:pt-84 xl:pb-84 xl:pl-100 2xl:pl-1-12 2xl:pr-1-01 relative">
                    <h2 className="pb-3 md:pb-10 font-poppings-bold text-rh2 lg:text-h2 text-white">
                      {services.frontmatter.title}</h2>
                    <div>
                      {leftArray.map((leftPost, i) =>
                        <div key={i} className="pb-6-6 grid grid-cols-6 sm:grid-cols-9 md:grid-cols-7">
                          <div className="col-span-1">
                            <img src={leftPost.svg.publicURL} alt={leftPost.alt} />
                          </div>
                          <div className="ml-1 lg:ml-0 col-span-5 sm:col-span-7 md:col-span-6">
                            <h4 className="font-poppings-bold text-rh3 lg:text-h4 text-white">
                              {leftPost.service_name}</h4>
                            <p className="font-worksans-normal text-p4 lg:text-p2 text-white opacity-60">{leftPost.description}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
              </div>
              <div className="w-full md:w-1/2 bg-background-secondary">
                  <div className="pt-61 pb-6 md:pb-50 pl-5-3 pr-35 lg:pl-8 xl:pt-88 xl:pr-1-12 2xl:pr-1-30 xl:pb-54 xl:pl-63">
                    {rightArray.map((rightPost, j) =>
                      <div key={j} className="pb-6-6 grid grid-cols-6 sm:grid-cols-9 md:grid-cols-7">
                        <div className="col-span-1">
                          <img src={rightPost.svg.publicURL} alt={rightPost.alt} />
                        </div>
                        <div className="ml-1 lg:ml-0 col-span-5 sm:col-span-7 md:col-span-6">
                          <h4 className="font-poppings-bold text-rh3 lg:text-h4 text-white">
                            {rightPost.service_name}</h4>
                          <p className="font-worksans-normal text-p4 lg:text-p2 text-white opacity-60">
                            {rightPost.description}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}


const CompanyServices = props => {
  return (
    <StaticQuery
      query={graphql`
          query {
            serviceData: markdownRemark(fileAbsolutePath: {regex: "/company-services-for-employee.md/"}) {
              frontmatter {
                title
                employee_services {
                  group {
                    description
                    alt
                    service_name
                    svg {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        `}
      render={data => <Services data={data} {...props} />}
    />
  );
};


export default CompanyServices;


