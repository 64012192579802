import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';


class TestimonialsEmployee extends Component {
  state = {
    showAll: false,
    ind: 0,
  }
  componentDidMount = () => {
    this.flikyE.on('change', (index) => {
      if (this.state.showAll) {
        this.setState({ showAll: false });
      }
    })
  }
  onMouseLeave = () => {
    this.flikyE.playPlayer()
  }
  showMore = (event, index) => {
    const el = event.target.closest("a");
    if (el && event.currentTarget.contains(el)) {
      this.setState({ showAll: true, ind: index });
    }
  }
  showLess = (event, index) => {
    const el = event.target.closest("a");
    if (el && event.currentTarget.contains(el)) {
      this.setState({ showAll: false, ind: index });
    }
  }

  contntLength = (content) => {
    const lenCnt = content.substr(3, content.length - 4)
    const final_l = lenCnt.length
    return final_l
  }
  render() {
    const { showAll, ind } = this.state;
    const limit = 240;
    const flickityOptions = {
      prevNextButtons: false,
      draggable: true,
      pageDots: false,
      wrapAround: true,
      autoPlay: false
    }
    return (
      <>
        <section className="pb-87 lg:pb-91">
          <div className="container mx-auto">
            <div className="pr-0 pl-5-3 flex flex-wrap our-employees-says">
              <div className="md:pt-2-1 w-full md:w-2/8">
                <hr className="border-cyan w-1-06 pb-2-3"></hr>
                <h6 className="font-poppings-bold text-rh6 lg:text-h6 text-cyan">
                  WHAT OUR EMPLOYEES <br className="hidden lg:block"></br> SAY </h6>
              </div>
              <StaticQuery
                query={graphql`
              query {
                text_testi: allMarkdownRemark(
                  sort: { order: ASC, fields: [frontmatter___date] }
                  filter: {frontmatter: {templateKey: {eq: "testimonial"},testimonial_type: {eq: "ETT"}}}
                  ) {
                    edges {
                      node {
                        frontmatter {
                          title
                          templateKey
                          testimonial_type
                          featured_image {
                            alt
                            image {
                              childImageSharp {
                                gatsbyImageData(
                                  placeholder: BLURRED
                                )
                              }
                            }
                          }
                          date
                        }
                        id
                        html
                      }
                    }
                  }
                }
                `}
                render={data => (
                  <div className="w-full md:w-6/8">
                    <div aria-hidden="true" className="t-slide-emp" onMouseLeave={this.onMouseLeave}>
                      <Flickity
                        flickityRef={c => this.flikyE = c}
                        className={'testimonial-emp-carousel'}
                        elementType={'div'}
                        options={flickityOptions} >
                        {data.text_testi.edges &&
                          data.text_testi.edges.map(({ node: post }, index) => (
                            <div key={index} className="t-emp-cell">
                              {this.contntLength(post.html) <= limit ?
                                <p
                                  dangerouslySetInnerHTML={{ __html: post.html }}
                                  className="pt-3-3 md:pt-0 lg:pb-4-1 pr-0 lg:pr-0 font-worksans-bold text-rp1 lg:text-p1 text-term-primary"></p>
                                :
                                (index === ind && showAll ?
                                  <>
                                    <p
                                      aria-hidden="true"
                                      onClick={(event) => this.showLess(event, index)}
                                      className="pt-3-3 md:pt-0 lg:pb-4-1 pr-0 lg:pr-0 font-worksans-bold text-rp1 lg:text-p1 text-term-primary"
                                      dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, this.contntLength(post.html)))} <a class="cursor-pointer font-poppings-bold text-cyan text-rp3 lg:text-p2 underline">see less</a>` }}
                                    />
                                  </>
                                  :
                                  <>
                                    <p
                                      aria-hidden="true"
                                      onClick={(event) => this.showMore(event, index)}
                                      className="pt-3-3 md:pt-0 lg:pb-4-1 pr-0 lg:pr-0 font-worksans-bold text-rp1 lg:text-p1 text-term-primary"
                                      dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, limit))} <a class="cursor-pointer font-poppings-bold text-cyan text-rp3 lg:text-p2 underline">see more</a>` }}
                                    />
                                  </>
                                )}
                              <div className="mt-3-3 lg:mt-0 flex flex-wrap items-center">
                                <div className="mr-4 w-10 h-10 md:w-50 md:h-50 lg:w-60 lg:h-60 rounded-full">
                                   <GatsbyImage image={getImage(post.frontmatter.featured_image.image)} alt={post.frontmatter.featured_image.alt} className="w-10 h-10 md:w-50 md:h-50 lg:w-60 lg:h-60  mx-auto rounded-full" />
                                </div>
                                <div className="font-worksans-normal text-rh6 lg:text-h5 text-term-primary">
                                  <p className="font-worksans-bold">{post.frontmatter.title}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Flickity>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default TestimonialsEmployee;