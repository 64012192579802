
import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import createReactClass from 'create-react-class'

//component
import Layout from "../components/layout";
import Seo from "../components/seo";
import EmailResume from "../components/Home/email-resume";
import Events from "../components/events"
import CompanyServices from "../components/Services/company-services"
import TestimonialsEmployee from "../components/Home/testimonials-employee"
import RecruitmentProcess from "../components/recruitment-process"

// images 
import whatsapp from "../images/uploads/whatsapp-blue.svg";
import email from "../images/uploads/email-blue.svg";
import telephone from "../images/uploads/telephone-blue.svg";


function autocreateslug(name) {
  let str = name.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
  str = str.replace(/^\s+|\s+$/gm, '');
  str = str.replace(/\s+/g, '-');
  return str
}

const Tabs = createReactClass({
  getInitialState() {
    return {
      selected: this.props.selected || 0
    }
  },
  componentDidMount: function () {
    const hashq = window.location.hash.substring(1)
    if (hashq) {
      let flag = -1

      this.props.children.map((elem, index) => {
        if (autocreateslug(elem.props.title) === hashq) {
          flag = index
        }
      })
      if (flag === -1) {
        this.setState({ selected: this.props.selected || 0 })
      } else {
        this.setState({ selected: flag })
      }
    } else {
      this.setState({ selected: this.props.selected || 0 })
    }
  },
  render() {
    return (
      <div>
        <div className="w-full overlow-hidden">
          <ul className="w-full whitespace-nowrap overflow-x-auto space-x-2 flex  faqs-list-container md:ml-2 mb-5">
            {this.props.children.map((elem, index) => {
              let style = index == this.state.selected ? "selected" : ""
              return (
                <li
                  className={`${style}  inline-block  px-3 sm:px-6-6 py-3  text-cyan font-poppings-bold text-p4 lg:text-p2`}
                  key={index}
                  onClick={this.handleChange.bind(
                    this,
                    index,
                    elem.props.title
                  )}
                >
                  {elem.props.title}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="tab">{this.props.children[this.state.selected]}</div>
      </div>
    )
  },
  handleChange(index, name) {
    let query_string = autocreateslug(name)

    window.location.hash = "#" + query_string
    this.setState({ selected: index })
  },
})

const Panel = createReactClass({
  render() {
    return <div>{this.props.children}</div>
  }
})


const JobOpening = (props) => {

  const pageData = props.data.JobspageData
  const SEOdata = props.data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpageData.edges[0].node.frontmatter?.Hreflang
  const allData = props.data.jobs.group
  const catdata = props.data.jobs_cat.edges
  const newdata = catdata.map(({ node: el }) => {
    return el.frontmatter.category_name
  })
  const result = allData.sort((a, b) => newdata.indexOf(a.fieldValue) - newdata.indexOf(b.fieldValue));
  const [list, setList] = useState(result)

  // useEffect(() => {

  //   // const result = list.sort((a, b) => (
  //   //   a.fieldValue - b.fieldValue
  //   // ));
  //   // const new_index = 0
  //   // const old_index = 1

  //   // if (new_index >= list.length) {
  //   //   var k = new_index - list.length + 1;
  //   //   while (k--) {
  //   //     list.push(undefined);
  //   //   }
  //   // }
  //   // list.splice(new_index, 0, list.splice(old_index, 1)[0]);
  //   // setList(list)
  // })


  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "job-opening"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner section */}

        <section className="mt-60 md:mt-67 lg:mt-20 lg:pb-54 overflow-hidden">
          <BgImage
            image={getImage(pageData.frontmatter.banner.image)}
            className="job-opning-bg relative"
            alt={`${pageData.frontmatter.banner.alt} | DecodeUp Job page banner`}
          >
            <div className="job-banner-overlay relative">
              <div className="container mx-auto text-center flex flex-wrap items-center lg:block h-full">
                <div className="py-90 mms:py-1-16 lg:pt-87 lg:pb-1-01 mx-auto relative z-60 w-full md:w-3/5 xl:w-2/5">
                  <p className="font-poppings-bold text-rh6 lg:text-h6 lg:pb-2 text-cyan">
                    {pageData.frontmatter.title}
                  </p>
                  <h2 className="text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-white job-opning-title">
                    {pageData.frontmatter.introduction}
                  </h2>
                </div>
              </div>
            </div>
          </BgImage>
        </section>

        {/* connect with us */}
        <section className="mt-10 lg:mt-0">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center bg-background-secondary justify-between p-6">
              <div className="pb-4 lg:pb-6 xl:pb-0 w-full xl:w-3/12">
                <h3 className="font-poppings-bold text-rp1 lg:text-h3 text-white sm:text-center 1md:text-left">
                  Connect with us!
                </h3>
              </div>
              <div className="w-full xl:w-9/12 grow flex flex-wrap sm:flex-nowrap items-center justify-between ">
                <div className="sm:w-3/12 1md:w-auto  flex items-center sm:flex-col 1md:flex-row pb-4 sm:pb-0">
                  <img
                    className="img-responsive  "
                    src={telephone}
                    alt="decodeup"
                  />
                  <div className="pl-3 sm:pl-0 1md:pl-3 sm:pt-1 1md:pt-0 sm:text-center 1md:text-left">
                    <p className="mb-0 text-p4 font-poppings-normal text-white opacity-40">
                      GIVE US A CALL NOW
                    </p>
                    <a
                      href={`tel:+918758332633`}
                      className="nav-family font-poppings-bold text-p3 lg:text-p2 text-white"
                    >
                      {"+91 8758-332633"}
                    </a>
                  </div>
                </div>
                <div className="sm:w-3/12 1md:w-auto flex items-center sm:flex-col 1md:flex-row pb-4 sm:pb-0">
                  <img
                    className="img-responsive"
                    src={email}
                    alt="decodeup"
                  />
                  <div className="pl-3 sm:pl-0 1md:pl-3 sm:pt-1 1md:pt-0 sm:text-center 1md:text-left">
                    <p className="mb-0 text-p4 font-poppings-normal text-white opacity-40">
                      WRITE US AN EMAIL
                    </p>
                    <a
                      href={`mailto:hr@decodeup.com `}
                      className="nav-family font-poppings-bold text-p3 lg:text-p2 text-white"
                    >
                      {"hr@decodeup.com "}
                    </a>
                  </div>
                </div>
                <div className="sm:w-3/12 1md:w-auto flex items-center sm:flex-col 1md:flex-row">
                  <img
                    className="img-responsive"
                    src={whatsapp}
                    alt="decodeup"
                  />
                  <div className="pl-3 sm:pl-0 1md:pl-3 sm:pt-1 1md:pt-0 sm:text-center 1md:text-left">
                    <p className="mb-0 text-p4 font-poppings-normal text-white opacity-40">
                      GET IN TOUCH
                    </p>
                    <a
                      target="_blank"
                      href="https://wa.me/message/UXSURN63L42OA1"
                      className="nav-family underline font-poppings-bold text-p3 lg:text-p2 text-white"
                    >
                      Chat with our Recruitment Team
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* job categories section */}

        <section className="pt-39 pb-50 xl:pt-77 lg:pb-1-16">
          <div className="container mx-auto">
            <Tabs selected={1}>
              {list &&
                list.map(({ fieldValue, edges }, index) => (
                
                  <Panel key={index} title={fieldValue}>
                    {edges.map(({ node }, i) => (
                      <div key={i} data-tt={i}>
                        <div className="mb-6-1 lg:mb-5 pt-6-6 pb-6-6 pl-5 pr-1 sm:pr-5 lg:pt-39 lg:pb-41 lg:px-20 border border-tone-ternary job-opning-box">
                          <h3 className="mb-1 lg:mb-2-1 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">{node.frontmatter.title}</h3>
                          <p className="pr-6-1 lg:pr-0 xl:pr-10 mb-5-2 lg:mb-6-4 text-p4 lg:text-p2 font-worksans-normal text-term-primary">{node.frontmatter.excerpt}</p>
                          <div className="flex flex-wrap justify-between items-starts">
                            <Link
                              to={node.fields.slug}
                              className="mb-2 md:mb-0 py-2-3 px-6-6  md:py-3 md:px-38 font-poppings-bold inline-block bg-celeste text-rh5 lg:text-h5 text-dark-blue text-center shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
                            > Apply Now </Link>
                            <div className="mr-2  mb-2 md:mb-0 flex flex-wrap items-center lg:items-starts">
                              <div className="mr-6-1 lg:mr-42">
                                <h4 className="font-poppings-bold text-rp3 lg:text-h4 text-cyan">{String(node.frontmatter.position).padStart(2, '0')}</h4>
                                <p className="text-rh6 lg:text-p4 font-poppings-normal text-dark-grey">POSITIONS</p>
                              </div>
                              <div>
                                <h4 className="font-poppings-bold text-rp3 lg:text-h4 text-cyan">{node.frontmatter.experience}</h4>
                                <p className="text-rh6 lg:text-p4 font-poppings-normal text-dark-grey">
                                  EXPERIENCE
                                </p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                  </Panel>
                ))}
            </Tabs>
          </div>
        </section>

        {/* our company services */}
        <section className="d-company-sevices">
          <CompanyServices />
        </section>

        {/* recrutment process slow carousel */}
        <RecruitmentProcess />

        {/* events and celebration */}
        <section className="pb-60 md:pb-20 lg:pb-1-20 pt-50 md:pt-90 lg:pt-1-21">
          <Events />
        </section>

        {/* employee testimonials */}
        <TestimonialsEmployee />

        {/* send us email resume section */}
        <EmailResume />
      </Layout>
    </>
  )
}

export default JobOpening;

export const pageQuery = graphql`
query {
  JobspageData: markdownRemark(fileAbsolutePath: {regex: "/jobs.md/"}) {
    frontmatter {
      title
      introduction
      banner {
        alt
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
  jobs: allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: {frontmatter: {templateKey: {eq: "job-opening"}}}
    ) {
    group(field: frontmatter___job_category) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            job_category
            title
            date
            excerpt
            experience
            position
          }
          id
          html
        }
      }
      fieldValue
    }
  }
  jobs_cat: allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: {frontmatter: {templateKey: {eq: "job-category"}}}
    ) {
      edges {
        node {
          frontmatter {
            category_name
        }
      }
    }
  }
  awardpageData: allMarkdownRemark(
    filter: { fileAbsolutePath: {regex: "/jobs.md/"} }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}
`;