import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby"


const EmailResume = () => {

  const data = useStaticQuery(graphql`
  query {
      content: markdownRemark(fileAbsolutePath: {regex: "/send-us-email-resume.md/"}) {
        frontmatter {
          sub_title
          description
        }
      }
    }
  `)
  const info = data.content.frontmatter
  return (
    <>
      <section>
        <div className="container mx-auto bg-background-secondary container-full">
          <div className="bg-dots-vector relative">
            <div className="pt-61 lg:px-100 lg:pt-1-06 pr-8 xl:pr-1-80 pb-49 lg:pb-1-22 pl-38 xl:pl-40 dots-vector-right-top items-center flex flex-wrap justify-between flex-wrap">
              <div className="w-full md:w-2/3">
                <h6 className="pb-3 md:pb-0 font-poppings-bold uppercase text-rh6 lg:text-h6 text-cyan">
                  {info.sub_title}
                </h6>
                <p className="pb-4-3 md:pb-0 font-worksans-bold text-rp1 lg:text-p1 text-white">
                  {info.description}
                </p>
              </div>
              <div className="w-full md:w-1/3 md:text-right">
                <a href="mailto:hr@decodeup.com"
                  className="pl-41 pr-10 py-3 md:px-46 z-10 font-poppings-bold inline-block text-center text-dark-blue text-h5 font-bold bg-celeste shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0" >
                  Email Resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailResume;
