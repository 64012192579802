import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';


class Events extends Component {
  updateDimensions = () => {
    var s_div = document.querySelector('.event-carousel .flickity-slider');
    if (s_div !== null) {
      this.flickity.on("select", () => {
        var slideNumber = this.flickity.selectedIndex + 1;
        if (slideNumber === 1) {
          s_div.style.transform = "translateX(0px)"
        }
      })
    }
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  render(props) {
    const flickityOptions = {
      prevNextButtons: true,
      draggable: true,
      pageDots: false,
      wrapAround: true,
      contain: true,
      cellAlign: 'left',
      imagesLoaded: true,
      percentPosition: false,
      initialIndex: 1,
    }
    return (
      <>
        <div className="container mx-auto">
          <div className="pb-8 lg:pb-42 mx-auto w-full xl:w-2/3 text-center">
            <h3 className="pb-1 lg:pb-1-1 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
              Events & Celebrations{" "}
            </h3>
            <p className="px-4 sm:px-0 text-p4 lg:text-p2 font-worksans-normal text-term-primary event-contains">
              Who said we’re all work and no fun? At DecodeUp, we like to
              celebrate the little things and the big things. Check out our
              latest celebrations below.
            </p>
          </div>
        </div>
        <StaticQuery
          query={graphql`
            query {
              events: markdownRemark(
                fileAbsolutePath: { regex: "/events.md/" }
              ) {
                frontmatter {
                  events_celebrations {
                    name
                    alt
                    image {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="container mx-auto d-event-main">
              <Flickity
                flickityRef={c => (this.flickity = c)}
                className={"event-carousel"}
                elementType={"div"}
                options={flickityOptions}
              >
                {data.events.frontmatter.events_celebrations &&
                  data.events.frontmatter.events_celebrations.map(
                    (post, index) => (
                      <div key={index} className="text-center e-carousel-cell">
                        <div className="event-img bg-light-grey">
                          <GatsbyImage
                            image={getImage(post.image)}
                            className="mx-auto"
                            alt={post.alt}
                          />
                        </div>
                        <div className="px-4 lg:px-6 pt-4-1 lg:pt-5-2">
                          <p className="event-title font-worksans-bold text-p3 md:text-p2 lg:text-h3 text-term-primary capitalize">
                            {post.name}
                          </p>
                        </div>
                      </div>
                    )
                  )}
              </Flickity>
            </div>
          )}
        />
      </>
    )
  }
}

export default Events;